import * as moment from 'moment';
import {CriteriaConditionOperator, CriteriaConditionOperatorOnly} from '../criteria/criteria-condition-operator';
import {CriteriaOperator} from '../criteria/criteria-operator';
import {ConditionValueType, CriteriaConditionJson} from '../criteria/json/criteria-condition-json';
import {CriteriaQueryGroupJson} from '../criteria/json/criteria-query-group-json';
import {CriteriaQueryJson} from '../criteria/json/criteria-query-json';
import {DateRange} from '../criteria/json/date-range';
import {Field} from '../model/response/field';
import {Attribute} from '../model/xml/attribute';
import {AbstractModelService} from '../service/coded/abstract-model.service';
import {EntityModel} from '../service/entities/entity-model';
import {Utility} from '../util/utility';
import {JsUtil} from './js-util';

export abstract class AbstractCriteriaBooleanCompiler {
  protected constructor(protected modelService: AbstractModelService) {
  }

  /**
   * create functions for every scripted condition and call as condition value
   */
  private static compileScriptedConditions(condition: CriteriaConditionJson, variable: string): void {
    // load defined condition, wrap in function and set value to function call
    // if condition.field === '$Script' it is a custom script appendix, so wrap in function and set value to call
    if (condition.scripted === ConditionValueType.SCRIPTED) {
      condition.value = `await (async function() {
  const condition = await ks.record.get('PreDefinedCondition', '${condition.value}');
  const script = context.jsCompiler.compile(condition.script, 'script');
  return context.runScript(script, {user, record: ${variable}}, undefined, 'PreDefinedCondition:' + condition.name + 'script').result;
})()`;
    } else if (condition.operator as any === CriteriaConditionOperatorOnly.SCRIPT ||
      condition.operator as any === CriteriaConditionOperatorOnly.STATIC_SCRIPT ||
      condition.scripted === ConditionValueType.SCRIPTED_VALUE) {
      const isAsync = condition.value.includes('await');
      condition.value = `${isAsync ? 'await ' : ''}(${isAsync ? 'async ' : ''}function() {
  ${condition.value}
})()`;
    }
  }

  /**
   * final step to combine field and value with the correct operator
   * respects to many dot walks properly
   */
  private static compileOperator(operator: CriteriaConditionOperator | CriteriaConditionOperatorOnly, field: string, value: any, isMultiple: boolean,
                                 isI18n: boolean, isJSON: boolean, variable: string): string {
    // add auto id access for relation and avoid adding duplicates
    const idAccess: string = field.endsWith(Utility.ID_SUFFIX) ? field : JsUtil.accessRelationAttribute(field.replace('[*].id', ''), 'id');
    const isArray = JsUtil.hasArrayAccess(field) || isMultiple;
    let check: string = '';

    if (isI18n) {
      check = '?.[user.language]';
    } else if (isJSON) {
      field = `JSON.stringify(${field})`;
    }

    switch (operator) {
      case CriteriaOperator.EQUAL:
        check += ` === ${value}`;
        return isArray ? `_.some(${field}, o => o${check})` : `${field}${check}`;
      case CriteriaOperator.NOT_EQUAL:
        check += ` !== ${value}`;
        return isArray ? `_.every(${field}, o => o${check})` : `${field}${check}`;
      case CriteriaOperator.IN:
        // at least one of a should be in b, so the difference should not be the value length
        return `_.difference(${value},${isArray ? field : `[${field}]`}).length !== (${value}).length`;
      case CriteriaOperator.ALL_IN:
        // everything from a is in b
        return `_.difference(${value},${isArray ? field : `[${field}]`}).length === 0`;
      case CriteriaOperator.NOT_IN:
        // nothing of a should be in b, so the difference should be the value length
        return `_.difference(${value},${isArray ? field : `[${field}]`}).length === (${value}).length`;
      case CriteriaOperator.BEGINS_WITH:
        check += `?.startsWith(${value})`;
        return isArray ? `_.some(${field}, o => o${check})` : `${field}${check}`;
      case CriteriaOperator.CONTAINS:
        check += `?.includes(${value})`;
        return isArray ? `_.some(${field}, o => o${check})` : `${field}${check}`;
      case CriteriaOperator.ENDS_WITH:
        check += `?.endsWith(${value})`;
        return isArray ? `_.some(${field}, o => o${check})` : `${field}${check}`;
      case CriteriaOperator.NOT_BEGINS_WITH:
        check += `?.startsWith(${value})`;
        return isArray ? `_.every(${field}, o => !o${check})` : `!${field}${check}`;
      case CriteriaOperator.NOT_CONTAINS:
        check += `?.includes(${value})`;
        return isArray ? `_.every(${field}, o => !o${check})` : `!${field}${check}`;
      case CriteriaOperator.NOT_ENDS_WITH:
        check += `?.endsWith(${value})`;
        return isArray ? `_.every(${field}, o => !o${check})` : `!${field}${check}`;
      case CriteriaConditionOperatorOnly.CHANGES:
        return `${variable}.changes('${field}')`;
      case CriteriaConditionOperatorOnly.NOT_CHANGES:
        return `!${variable}.changes('${field}')`;
      case CriteriaConditionOperatorOnly.CHANGES_TO:
        return `${variable}.changesTo('${field}', ${value})`;
      case CriteriaConditionOperatorOnly.NOT_CHANGES_TO:
        return `!${variable}.changesTo('${field}', ${value})`;
      case CriteriaConditionOperatorOnly.CHANGES_FROM:
        return `${variable}.changesFrom('${field}', ${value})`;
      case CriteriaConditionOperatorOnly.NOT_CHANGES_FROM:
        return `!${variable}.changesFrom('${field}', ${value})`;
      case CriteriaOperator.IS_NOT_EMPTY:
      case CriteriaOperator.IS_NOT_NULL:
        return isArray ? `_.some(${field}, o => !_.isNullOrEmpty(o))` : `!_.isNullOrEmpty(${field})`;
      case CriteriaOperator.IS_EMPTY:
      case CriteriaOperator.IS_NULL:
        return isArray ? `_.some(${field}, o => _.isNullOrEmpty(o))` : `_.isNullOrEmpty(${field})`;
      case CriteriaOperator.LESS:
        check = ` < ${value}`;
        return isArray ? `_.some(${field}, o => o${check})` : `${field}${check}`;
      case CriteriaOperator.LESS_OR_EQUAL:
        check = ` <= ${value}`;
        return isArray ? `_.some(${field}, o => o${check})` : `${field}${check}`;
      case CriteriaOperator.GREATER:
        check = ` > ${value}`;
        return isArray ? `_.some(${field}, o => o${check})` : `${field}${check}`;
      case CriteriaOperator.GREATER_OR_EQUAL:
        check = ` >= ${value}`;
        return isArray ? `_.some(${field}, o => o${check})` : `${field}${check}`;
      case CriteriaOperator.BETWEEN:
        return isArray ? `_.some(${field}, o => o >= ${value[0]} && o <= ${value[1]})` : `${field} >= ${value[0]} && ${field} <= ${value[1]}`;
      case CriteriaOperator.NOT_BETWEEN:
        return isArray ? `_.some(${field}, o => o < ${value[0]} || o > ${value[1]})` : `${field} < ${value[0]} || ${field} > ${value[1]}`;
      case CriteriaOperator.IS:
        check = ` === ${value}`;
        return isArray ? `_.some(${idAccess}, o => o${check})` : `${idAccess}${check}`;
      case CriteriaOperator.IS_NOT:
        check = ` !== ${value}`;
        return isArray ? `_.every(${idAccess}, o => o${check})` : `${idAccess}${check}`;
      case CriteriaOperator.DATE_RANGE:
        const startOfToday: moment.Moment = moment.utc().startOf('day');
        const endOfToday: moment.Moment = moment.utc().endOf('day');
        const range = value as DateRange;
        // first subtract desired amount
        let startDate: moment.Moment = startOfToday.subtract(range.amount, range.unit);
        let endDate = endOfToday;
        // decide if it should only go back from today or the whole unit period
        // e.g. last month => fromToday: false, only include 1.7-31.7,  last month => fromToday: true, only include today-30days
        if (!range.fromToday) {
          startDate = startDate.startOf(range.unit);
          // so for e.g. last 6 month we have start at 1.1 and it should end in 31.6 when used in july and we have to go back 1 month for the end date
          // for amount of 0 which means the current time period e.g. this month, we do not subtract a flat one
          endDate = endOfToday.subtract(range.amount ? 1 : 0, range.unit).endOf(range.unit);
        }

        return isArray ? `_.some(${idAccess}, o => o >= ${startDate.valueOf()} && o <= ${endDate.valueOf()})` :
          `${field} >= ${startDate.valueOf()} && ${field} <= ${endDate.valueOf()}`;
      case CriteriaOperator.MEMBER_OF:
        return `${field}.includes(${value})`;
      case CriteriaOperator.NOT_MEMBER_OF:
        return `!${field}.includes(${value})`;
      case CriteriaConditionOperatorOnly.HAS_ROLE:
        return `user.hasRole(${value})`;
      case CriteriaConditionOperatorOnly.HAS_ROLES:
        return 'user.hasRoles()';
      case CriteriaConditionOperatorOnly.HAS_GROUP:
        return `user.hasGroup(${value})`;
      case CriteriaConditionOperatorOnly.HAS_PERMISSION:
        const aclType = Utility.convertLegacyAclType(value);
        return `await (async function() {
          let recordForCheck = record;
          if ('${value.resource}' !== '*' && record?.entityClass !== '${value.resource}') {
            try {
              recordForCheck = await ks.record.get('${value.resource}'); 
            } catch (e) {
              if (e.status === 403) {
                return false;
              }
              throw e;
            }
          }
          return user.can('${value.operation}', '${value.resource}', null, {type: '${aclType}', record: recordForCheck})
         })()`;
      case CriteriaConditionOperatorOnly.SCRIPT:
      case CriteriaConditionOperatorOnly.STATIC_SCRIPT:
        return value;
    }
  }

  /**
   * generate a dot walk with await and map to get the value of the field
   */
  public compileFieldAccess(entity: string, variable: string, field?: string, isMultiple: boolean = false): string {
    const entityModel = this.modelService.getEntity(entity);

    if (!entity) {
      return '';
    }

    if (!field) {
      return variable;
    }

    return this.accessField(entityModel, variable, field, false, isMultiple).currentJs;
  }

  /**
   * compiles the query to a js string, that returns boolean
   */
  protected compileBooleanScript(query: CriteriaQueryJson, variable: string = 'record'): string {
    for (const condition of Utility.getAllConditions(query)) {
      AbstractCriteriaBooleanCompiler.compileScriptedConditions(condition, variable);
    }
    const s = this.compileGroup(query, this.modelService.getEntity(query.entity), variable);

    if (!s) {
      return '';
    }

    return `return ${s}`;
  }

  /**
   * recursive function for compiling criteria groups
   * append conditions and nested groups
   */
  private compileGroup(group: CriteriaQueryGroupJson, entity: EntityModel, variable: string): string {
    let statement = '';
    for (const condition of (group.whereCondition || []).filter(c => c.active !== false)) {
      if (!condition.columnName) {
        continue;
      }
      const conditionStatement = this.compileCondition(condition, entity, variable);
      statement = JsUtil.appendStatement(statement, conditionStatement, condition.or);
    }
    for (const subGroup of (group.groups || []).filter(c => c.active !== false)) {
      const conditionStatement = this.compileGroup(subGroup, entity, variable);
      if (conditionStatement) {
        statement = JsUtil.appendStatement(statement, conditionStatement, subGroup.useOr);
      }
    }
    return statement;
  }

  /**
   * compile a single condition by converting field, value and finally combining both with the operator
   */
  private compileCondition(condition: CriteriaConditionJson, entity: EntityModel, variable: string): string {
    const fieldInfo = this.compileField(condition.columnName, condition.operator, entity, variable);
    const value = (condition.scripted === ConditionValueType.SCRIPTED || condition.scripted === ConditionValueType.SCRIPTED_VALUE ||
      condition.operator as any === CriteriaConditionOperatorOnly.STATIC_SCRIPT ||
      condition.operator as any === CriteriaConditionOperatorOnly.SCRIPT) ? condition.value : this.compileValue(condition, entity);
    return AbstractCriteriaBooleanCompiler.compileOperator(condition.operator, fieldInfo.currentJs, value, fieldInfo.isMultiple, fieldInfo.isI18n,
      fieldInfo.isJSON, variable);
  }

  /**
   * compile a field by awaiting relation and dot walking attributes
   */
  private compileField(columnName: string, operator: CriteriaConditionOperator, entity: EntityModel,
                       variable: string): { currentJs: string; isMultiple: boolean; isI18n: boolean; isJSON: boolean } {
    if (Utility.isFunctionalOperator(operator)) {
      // this one required the field to be a string for access
      return {currentJs: columnName, isMultiple: false, isI18n: false, isJSON: false};
    }
    return this.accessField(entity, variable, columnName);
  }

  /**
   * insert awaits and flat maps where required to access the field
   */
  private accessField(entity: EntityModel, variable: string, fieldDotWalk: string, simplify: boolean = true,
                      wasToMany: boolean = false): { currentJs: string; isMultiple: boolean; isI18n: boolean; isJSON: boolean } {
    const path = fieldDotWalk.split('.');
    let currentJs = variable;
    let isMultiple: boolean = false;
    let isI18n: boolean = false;
    let isJSON: boolean = false;
    this.modelService.iterateObject(entity, '', path, (field, entityMeta, last, column) => {
      // I have no idea what this field might be so assuming it's a plain field on the object
      let fieldName = !field ? column : field.name;
      const simplifyToOne: boolean = simplify && field && Utility.isToOneRelation(field) && last;
      if (simplifyToOne) {
        fieldName = Utility.parameterizeEntityName(field.name);
      }
      // if prev access was a relation
      if (Utility.isRelation(field) && !simplifyToOne) {
        // we have to access the collection with [*]
        const isToMany = Utility.isToManyField(field);
        currentJs = JsUtil.accessRelationAttribute(currentJs, fieldName, wasToMany, isToMany);
        if (isToMany) {
          wasToMany = true;
        }
      } else {
        isMultiple = (field as Attribute)?.multiple && last;
        currentJs = JsUtil.accessAttribute(currentJs, fieldName, wasToMany);
      }

      const typeId = (field as Attribute)?.typeId;
      switch (typeId) {
        // if this is a date convert it to a timestamp value
        case '38a7d898-da61-4b1f-a8b0-65a9bb73d36b':
          currentJs = JsUtil.convertDate(currentJs, false) as string;
          break;
        case '3bd3ea2e-7cc6-461e-93f1-748c3f2d0354':
          isI18n = true;
          break;
        case '38a7d898-da62-4b1f-a8b0-65a9bb73d36b':
        case '424416c0-16bf-403a-89fd-696f5b25b705':
          isJSON = true;
          break;
      }
    });
    return {currentJs, isMultiple, isI18n, isJSON};
  }

  /**
   * compile value to a properly usable string in js
   */
  private compileValue(condition: CriteriaConditionJson, entity: EntityModel): string | string[] {
    const field1: Field = this.modelService.getField(entity.name, condition.columnName);
    const relation: boolean = Utility.isRelation(field1);
    if (Array.isArray(condition.value)) {
      // convert all types of array and then convert array property
      const converterArray = condition.value.map(value1 => JsUtil.convertValueToString(value1, field1 as Attribute, condition.operator, relation));


      if (condition.operator === CriteriaOperator.BETWEEN || condition.operator === CriteriaOperator.NOT_BETWEEN) {
        return converterArray;
      }
      return JsUtil.convertArray(converterArray);
    } else {
      // check attribute type and convert correctly
      return JsUtil.convertValueToString(condition.value, field1 as Attribute, condition.operator, relation);
    }
  }
}
