<p-table #table (onLazyLoad)="load($event)" (onRowReorder)="onRowReorder.emit($event)" (onStateRestore)="modifyStateRestore($event)"
         (onStateSave)="modifyStateSave($event)" [(contextMenuSelection)]="contextSelection" [(selection)]="selection"
         [alwaysShowPaginator]="!loading && totalRecords !== 0" [columns]="columns" [contextMenu]="cm" [dataKey]="dataKey" [filterDelay]="800"
         [frozenWidth]="frozenWidth" [globalFilterFields]="globalFilterFields" [lazy]="lazy" [loading]="loading" [multiSortMeta]="multiSortMeta" [pageLinks]="7"
         [paginator]="true" [resizableColumns]="isResizable" [rowTrackBy]="rowTrackBy" [rowsPerPageOptions]="rowsPerPageOptions" [rows]="rows"
         [scrollable]="isResizable" [selectionMode]="selectionMode" [selectionPageOnly]="true" [showCurrentPageReport]="true" [showLoader]="false"
         [stateKey]="name" [totalRecords]="exactTotalRecords ?? totalRecords" [value]="values" class="{{styleClass}} p-datatable-wrapper"
         columnResizeMode="expand"
         currentPageReportTemplate="{{(lazy && !exactTotalRecords ? 'List.EstimatedPaginatorTextGroup' : 'List.PaginatorTextGroup') | translate}}"
         editMode="row" paginatorDropdownAppendTo="body" sortMode="multiple" stateStorage="session">

  <ng-template pTemplate="caption">
    <h2 *ngIf="title" class="one-section__title">
      <ng-container *ngIf="icon">
        <i [ngClass]="{'one-section__icon--margin': !!icon}" class="one-section__icon {{icon}}"></i>
      </ng-container>
      {{ title }}
    </h2>
    <ng-container *ngIf="tHeader" [ngTemplateOutlet]="tHeader"></ng-container>

    <div class="one-section__tools">
      <ui-guided-tour-step cssDisplayValue="inline-flex" reservedStepName="resDatatableToolsStep">
        <ng-container *ngIf="tTools" [ngTemplateOutlet]="tTools"></ng-container>
        <ui-button (clicked)="list.create($event.cb)" *ngIf="hasCreateAction" icon="fas fa-fw fa-plus" label="{{'List.Actions.Create' | translate}}"
                   type="icon-only"></ui-button>
      </ui-guided-tour-step>

      <ng-container *ngTemplateOutlet="tToolbarButton ; context: {table: this}"></ng-container>
      <ui-guided-tour-step reservedStepName="resDatatableGlobalSearchStep">
        <ui-search-input (search)="table.filterGlobal($event, 'contains')" *ngIf="globalSearch" [isCompact]="compact"
                         [value]="filterState('global').value"></ui-search-input>
      </ui-guided-tour-step>
    </div>
    <div *ngIf="tSubHeader" class="one-section__subheader">
      <ng-container [ngTemplateOutlet]="tSubHeader"></ng-container>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th *ngIf="hasSelectAction" class="one-datatable__column--checkbox one-datatable__column--sticky one-datatable__column--sticky-left">
        <ng-container *ngIf="selectionMode === ListSelectionMode.MULTIPLE">
          <p-checkbox #selectAllCheckbox (onChange)="doSelectAll(selectAllCheckbox.checked())" [pTooltip]="'List.Select.CurrentPage' | translate"
                      [value]="selectAllCheckboxValue" tooltipPosition="top"></p-checkbox>
        </ng-container>
      </th>
      <th *ngFor="let col of groupingColumns" [ngClass]="{
        'one-datatable__column--image': typeUtility.getKolibriType(col.meta) === 'attachment',
        'one-datatable__column--date': col.typeName.toLowerCase() === 'date',
        'one-datatable__column--min-width': hasMinWidth(col)
      }" [pResizableColumnDisabled]="!resizeable" [pSortableColumnDisabled]="!col.sortable ||
          !isColAttributeInteractable(col)" [pSortableColumn]="col.field" pResizableColumn>

        <ng-container *ngIf="typeUtility.getKolibriType(col.meta) === 'attachment'; else normalHeader">
          <i class="one-datatable__image-column-icon far fa-fw fa-image"></i>
        </ng-container>

        <ng-template #normalHeader>
          <div class="ui-column-header">

            <div class="ui-column-title" title="{{col.header}}">
              {{ col.header }}
            </div>
            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
          </div>
        </ng-template>
        <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" *ngIf="col.filterable &&
        isColAttributeInteractable(col) && showFilters" [ngClass]="{'ui-column-customfilter': true, 'ui-column-customfilter--active':
        (tableState?.filters && tableState.filters[col.field]?.value && tableState.filters[col.field].value.length !== 0)}">
          <ng-container [ngSwitch]="lazy ? col.filterType : 'text'">
            <ng-container *ngSwitchCase="FilterType.MULTISELECT">
              <p-columnFilter [field]="col.field" [showClearButton]="false" [showMenu]="false" display="row" matchMode="in">
                <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                  <ui-entity-multiselect (onChange)="filter($event)" *ngIf="col.typeName.toLowerCase() !== 'choice'" [field]="col.field"
                                         [filterScript]="col.filterScript" [matchMode]="col.defaultFilterOperator" [ngModel]="value" [tableId]="table.id"
                                         [targetEntity]="col.meta.targetId" placeholder="Filter"></ui-entity-multiselect>
                  <ui-choice-multiselect (onChange)="filter($event)" *ngIf="col.typeName.toLowerCase() === 'choice'" [choiceId]="col.type.id"
                                         [field]="col.field" [filterScript]="col.choiceFilterScript" [ngModel]="value" [tableId]="table.id"
                                         placeholder="Filter"></ui-choice-multiselect>
                </ng-template>
              </p-columnFilter>
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.SLIDER">
              <p-columnFilter [field]="col.field" [showClearButton]="false" [showMenu]="false" display="row" matchMode="between">
                <ng-template let-filter="filterCallback" let-values pTemplate="filter">
                  <ui-slider (onChange)="filter($event.values)" [max]="col.sliderMaxValue" [min]="col.sliderMinValue" [values]="values"></ui-slider>
                </ng-template>
              </p-columnFilter>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <ng-container [ngSwitch]="col.typeName.toLowerCase()">
                <p-columnFilter *ngSwitchCase="'boolean'" [field]="col.field" [type]="getColumnFilterType(col.typeName.toLowerCase())" display="row"
                                matchMode="equals" placeholder="Filter">
                  <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                    <p-selectButton (onChange)="filter($event.value)" *ngIf="col.typeName.toLowerCase() === 'boolean'" [ngModel]="value"
                                    [options]="booleanFilters" styleClass="one-selectbutton one-selectbutton--filter"></p-selectButton>
                  </ng-template>
                </p-columnFilter>
                <ng-container *ngSwitchDefault>

              <span class="p-column-filter-operator-icon fa-stack">
                <i *ngFor="let icon of convertMatchModeToIcon(col)" class="{{icon}} fa-stack-1x"></i>
              </span>
                  <p-columnFilter [field]="col.field" [matchMode]="col.defaultFilterOperator" [type]="getColumnFilterType(col.typeName.toLowerCase())"
                                  display="row" placeholder="Filter">
                    <ng-template *ngIf="col.typeName.toLowerCase() === 'date'" let-filter="filterCallback" let-value pTemplate="filter">
                      <div class="p-column-filter-date-granularity">
                        <i (click)="menu.show($event)" [class]="getDateGranularityIcon(col)"></i>
                      </div>
                      <p-menu #menu [model]="getDateGranularityMenu(col)" [popup]="true" appendTo="body" class="p-column-filter-date-granularity-menu"></p-menu>
                      <ui-calendar (ngModelChange)="filter($event)" [ngModel]="value" [renderInputGroup]="false" [showSeconds]="false" [showTime]="(col.transformationEntity.dateType ===
                                   DateType.TIME || col.transformationEntity.dateType === DateType.DATETIME) && (filterState(col.field)?.granularity === 'i'
                                   || filterState(col.field)?.granularity === 'h')" [timeOnly]="col.transformationEntity.dateType === DateType.TIME"
                                   placeholder="Filter"></ui-calendar>
                    </ng-template>
                  </p-columnFilter>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </th>
      <th *ngIf="hasAnyColumnAction && !hasSelectAction"
          class="one-datatable__column--action one-datatable__column--sticky one-datatable__column--sticky-right">
        <div class="ui-column-header">
          <div class="ui-column-title" title="{{'List.Actions.Title' | translate}}">
            {{ 'List.Actions.Title' | translate }}
          </div>
        </div>
        <ng-container *ngIf="hasEditAction && viewInitReady">
          <div>
            <ui-button (clicked)="enableEditForAll()" *ngIf="hasEditAllAction" [spinner]="false" [type]="['icon-only']" class="one-datatable__column-button"
                       icon="fas fa-fw fa-pen" label="{{'List.Actions.Edit' | translate}}"></ui-button>
            <ui-button (clicked)="saveAllEdited()" *ngIf="hasSaveCancelAllAction" [spinner]="false" [type]="['icon-only', 'green']"
                       class="one-datatable__column-button" icon="fas fa-fw fa-check" label="{{'List.Actions.Save' | translate}}"></ui-button>
            <ui-button (clicked)="cancelAllEdited()" *ngIf="hasSaveCancelAllAction" [spinner]="false" [type]="['icon-only', 'red']"
                       class="one-datatable__column-button" icon="fas fa-fw fa-times" label="{{'List.Actions.Cancel' | translate}}"></ui-button>
          </div>
        </ng-container>
      </th>
    </tr>
  </ng-template>

  <ng-template #loadBody pTemplate="loadingbody">
    <tr class="one-datatable__row--group-loading">
      <td [attr.colspan]="columnCount">
        <div class="one-datatable--loading">
          <i class="fa fa-fw fa-spinner fa-pulse"></i>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template let-editing="editing" let-index="rowIndex" let-outerData pTemplate="body">
    <ng-container *ngIf="!loading && groupByColumn as outerCol">
      <tr [ngClass]="{
                'one-datatable__group-header': true,
                'one-datatable__group--open': getGroupingValueObject(outerData, outerCol).ready,
                'one-datatable__group--closed': !getGroupingValueObject(outerData, outerCol).ready,
              }" [pContextMenuRow]="outerData">
        <td (contextmenu)="contextFieldSelection = outerCol.field" [colSpan]="columnCount" class="one-datatable__group-header-cell">
          <h5 (click)="toggleDataTable(outerData, outerCol)" class="one-datatable__group-header-title one-helper--pointer">
            <i [ngClass]="{
                'one-datatable__group-header-toggle': true,
                'fa fa-fw fa-chevron-down': getGroupingValueObject(outerData, outerCol).ready,
                'fas fa-fw fa-chevron-right': !getGroupingValueObject(outerData, outerCol).ready,
              }"></i>

            <ui-field-translator [converter]="outerCol.converter" [data]="outerData" [field]="outerCol.displayField ?? outerCol.field" [meta]="outerCol.meta"
                                 [renderType]="getOuterRenderType(outerCol)" [rounded]="outerCol.rounded" [transformId]="outerCol.transformationEntity.id"
                                 [transformScript]="getOuterTransformScript(outerCol)" [typeName]="outerCol.typeName"></ui-field-translator>
          </h5>
        </td>
      </tr>
      <ng-container *ngIf="getGroupingValueObject(outerData, outerCol).ready && getGroupingValueObject(outerData, outerCol) as groupingType">
        <ng-container *ngIf="!groupingType.loading else loadBody">
          <ng-container *ngFor="let data of groupingType.values">
            <tr (click)="viewOnClick($event, data, isEditingValue(groupingType, data))" [id]="table.id + '-' + index" [ngClass]="{
            'one-datatable__row--group': true,
            'one-datatable__row--selectable': !isEditingValue(groupingType, data) && (hasSelectAction || hasViewAction),
            'one-datatable__row--editable': isEditingValue(groupingType, data),
            'one-datatable__row--quarantined': data.quarantined,
            }" [pContextMenuRow]="data" [pEditableRow]="data" [pSelectableRowDisabled]="!hasSelectAction" [pSelectableRow]="data">
              <td *ngIf="hasSelectAction" class="one-datatable__column--checkbox one-datatable__column--sticky one-datatable__column--sticky-left">
                <p-tableCheckbox (click)="$event.stopPropagation()" *ngIf="selectionMode === ListSelectionMode.MULTIPLE" [value]="data"></p-tableCheckbox>
                <p-tableRadioButton (click)="$event.stopPropagation()" *ngIf="selectionMode === ListSelectionMode.SINGLE" [value]="data"></p-tableRadioButton>
              </td>
              <td (contextmenu)="contextFieldSelection = col.field" *ngFor="let col of groupingColumns" [ngClass]="{
        'one-datatable__column--inline one-datatable__column--image': typeUtility.getKolibriType(col.meta) === 'attachment',
        'one-datatable__column--date': col.typeName.toLowerCase() === 'date',
        'one-datatable__column--number': col.typeName.toLowerCase() === 'number',
        'one-datatable__column--min-width': hasMinWidth(col),
        'one-datatable__column--editable': isEditingValue(groupingType, data) && col.editable,
        }">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <ui-field-value-selector *ngIf="!col.field.includes('.') && col.editable && canEdit(data) else output" [data]="data"
                                             [entityName]="col.meta.targetId" [fieldMeta]="col.meta" [field]="col.field"
                                             [transformId]="col.transformationEntity.id"></ui-field-value-selector>
                  </ng-template>
                  <ng-template #output pTemplate="output">
            <span class="one-datatable__column-header--responsive">
              {{ col.header }}:
            </span>
                    <ui-field-translator [converter]="col.converter" [data]="data" [field]="col.displayField ?? col.field" [meta]="col.meta"
                                         [renderType]="col.renderType" [rounded]="col.rounded" [transformId]="col.transformationEntity.id"
                                         [transformScript]="col.transformScript" [typeName]="col.typeName"></ui-field-translator>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td (click)="$event.stopPropagation()" *ngIf="hasAnyColumnAction && !hasSelectAction"
                  class="one-datatable__column--inline one-datatable__column--action one-datatable__column--sticky one-datatable__column--sticky-right">
                  <span class="one-datatable__column-header--responsive">
                    {{ 'List.Actions.Title' | translate }}
                  </span>
                <ng-container *ngIf="tRowActions && !isEditingValue(groupingType, data)">
                  <ng-container *ngTemplateOutlet="tRowActions; context: {data, index}"></ng-container>
                </ng-container>
                <ui-button (clicked)="delete(data, $event.cb)" *ngIf="hasDeleteAction && canDelete(data)" [type]="['icon-only', 'red']"
                           icon="fas fa-fw fa-trash" label="{{'List.Actions.Delete' | translate}}"></ui-button>
                <ng-container *ngIf="hasEditAction && canEdit(data)">
                  <ui-button (clicked)="enableEdit(groupingType, data)" *ngIf="!isEditingValue(groupingType, data)" [spinner]="false" [type]="['icon-only']"
                             icon="fas fa-fw fa-pen" label="{{'List.Actions.Edit' | translate}}" pInitEditableRow></ui-button>
                  <ui-button (clicked)="saveEdit(groupingType, data)" *ngIf="isEditingValue(groupingType, data)" [spinner]="false"
                             [type]="['icon-only', 'green']" icon="fas fa-fw fa-check" label="{{'List.Actions.Save' | translate}}" pSaveEditableRow></ui-button>
                  <ui-button (clicked)="cancelEdit(groupingType, data)" *ngIf="isEditingValue(groupingType, data)" [spinner]="false"
                             [type]="['icon-only', 'red']" icon="fas fa-fw fa-times" label="{{'List.Actions.Cancel' | translate}}"
                             pCancelEditableRow></ui-button>
                </ng-container>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngTemplateOutlet="innerFooter; context: {$implicit: groupingType, outerData, outerCol}"></ng-container>
        </ng-container>
        <ng-container *ngTemplateOutlet="innerPaginator; context: {$implicit: groupingType, outerData, outerCol}"></ng-container>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #innerFooter let-groupingType let-outerCol="outerCol" let-outerData="outerData" pTemplate="innerFooter">
    <tr *ngIf="!groupingType.loading && groupingType.totalRecords !== 0" class="one-datatable__group-footer">
      <td *ngFor="let col of groupingColumns" class="one-datatable__column--footer">
        <ng-container *ngIf="col.aggregation && col.showAggregation">
        <span class="one-datatable__column-header--responsive">
          {{ col.header }}:
        </span>
          <div class="one-datatable__aggregation">
            <div *ngIf="col.aggregation && col.perPageAggregation" class="one-datatable__aggregation-item">
              <div>{{ 'List.Footer.Page.' + col.aggregation | translate }}:</div>
              <ui-input-number [currency]="col.transformationEntity.symbol" [disable]="true" [locale]="translate.currentLang"
                               [maxFractionDigits]="col.transformationEntity.decimalPlaces" [minFractionDigits]="col.transformationEntity.decimalPlaces"
                               [renderInputGroup]="false" [value]="aggregateInner(groupingType, col)"></ui-input-number>
            </div>
            <div *ngIf="col.aggregation && col.totalAggregation" class="one-datatable__aggregation-item">
              <div>{{ 'List.Footer.' + col.aggregation | translate }}:</div>
              <ui-input-number [currency]="col.transformationEntity.symbol" [disable]="true" [locale]="translate.currentLang"
                               [maxFractionDigits]="col.transformationEntity.decimalPlaces" [minFractionDigits]="col.transformationEntity.decimalPlaces"
                               [renderInputGroup]="false" [value]="groupingType.aggregationValue[col.field]"></ui-input-number>
            </div>
          </div>
        </ng-container>
      </td>
      <td *ngIf="hasAnyColumnAction && !hasSelectAction">
        <!--     empty column     -->
      </td>
    </tr>
  </ng-template>

  <ng-template #innerPaginator let-groupingType let-outerCol="outerCol" let-outerData="outerData" pTemplate="innerPaginator">
    <tr class="one-datatable__group-paginator">
      <td [colSpan]="columnCount">
        <p-paginator (onPageChange)="onPageChange(groupingType, outerData, outerCol, $event)"
                     [alwaysShow]="!groupingType.loading && groupingType.totalRecords !== 0" [first]="groupingType.first"
                     [rowsPerPageOptions]="rowsPerPageOptions" [rows]="groupingType.rows" [showCurrentPageReport]="true" [templateLeft]="templateLeft"
                     [templateRight]="tempplateRight" [totalRecords]="groupingType.exactTotalRecords ?? groupingType.totalRecords" currentPageReportTemplate="{{(lazy && !groupingType.exactTotalRecords ? 'List.EstimatedPaginatorText' : 'List.PaginatorText') |
                       translate}}" dropdownAppendTo="body">
          <ng-template #templateLeft>
            <ui-button (clicked)="countInner(groupingType)" *ngIf="lazy" [spinner]="false" icon="fa fa-fw fa-calculator" label="{{'List.Count' | translate}}"
                       type="icon-only"></ui-button>
          </ng-template>
          <ng-template pTemplate="firstpagelinkicon">
            <span class="p-paginator-icon fa fa-angle-double-left"></span>
          </ng-template>
          <ng-template pTemplate="lastpagelinkicon">
            <span class="p-paginator-icon fa fa-angle-double-right"></span>
          </ng-template>
          <ng-template pTemplate="previouspagelinkicon">
            <span class="p-paginator-icon fa fa-angle-left"></span>
          </ng-template>
          <ng-template pTemplate="nextpagelinkicon">
            <span class="p-paginator-icon fa fa-angle-right"></span>
          </ng-template>
          <ng-template #tempplateRight>
            <ui-button (clicked)="refreshInner(groupingType, outerData, outerCol)" [spinner]="false" icon="fa fa-fw fa-sync" label="{{'List.Refresh' |
              translate}}" type="icon-only"></ui-button>
          </ng-template>
        </p-paginator>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <ui-button (clicked)="count()" *ngIf="lazy" [spinner]="false" icon="fa fa-fw fa-calculator" label="{{'List.Count' | translate}}"
               type="icon-only"></ui-button>
  </ng-template>

  <ng-template pTemplate="paginatorfirstpagelinkicon">
    <span class="p-paginator-icon fa fa-angle-double-left"></span>
  </ng-template>
  <ng-template pTemplate="paginatorlastpagelinkicon">
    <span class="p-paginator-icon fa fa-angle-double-right"></span>
  </ng-template>
  <ng-template pTemplate="paginatorpreviouspagelinkicon">
    <span class="p-paginator-icon fa fa-angle-left"></span>
  </ng-template>
  <ng-template pTemplate="paginatornextpagelinkicon">
    <span class="p-paginator-icon fa fa-angle-right"></span>
  </ng-template>

  <ng-template pTemplate="paginatorright">
    <ui-button (clicked)="refresh(undefined, true)" [spinner]="false" icon="fa fa-fw fa-sync" label="{{'List.Refresh' | translate}}"
               type="icon-only"></ui-button>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="columnCount">
        <div class="one-datatable--empty">{{ 'AutoComplete.NoResults' | translate }}</div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer">
    <tr *ngIf="!loading && totalRecords !== 0 && (showPageAggregationInGrouping || showTotalAggregationInGrouping)">
      <td *ngFor="let col of groupingColumns" class="one-datatable__column--footer">
        <ng-container *ngIf="col.aggregation && col.showAggregation">
        <span class="one-datatable__column-header--responsive">
          {{ col.header }}:
        </span>
          <div class="one-datatable__aggregation">
            <div *ngIf="showPageAggregationInGroupingForColumn(col)" class="one-datatable__aggregation-item">
              <div>{{ 'List.Footer.Page.' + col.aggregation | translate }}:</div>
              <ui-input-number [currency]="col.transformationEntity.symbol" [disable]="true" [locale]="translate.currentLang"
                               [maxFractionDigits]="col.transformationEntity.decimalPlaces" [minFractionDigits]="col.transformationEntity.decimalPlaces"
                               [renderInputGroup]="false" [value]="aggregate(col)"></ui-input-number>
            </div>
            <div *ngIf="showTotalAggregationInGroupingForColumn(col)" class="one-datatable__aggregation-item">
              <div>{{ 'List.Footer.' + col.aggregation | translate }}:</div>
              <ui-input-number [currency]="col.transformationEntity.symbol" [disable]="true" [locale]="translate.currentLang"
                               [maxFractionDigits]="col.transformationEntity.decimalPlaces" [minFractionDigits]="col.transformationEntity.decimalPlaces"
                               [renderInputGroup]="false" [value]="col.aggregationValue"></ui-input-number>
            </div>
          </div>
        </ng-container>
      </td>
      <td *ngIf="hasAnyColumnAction && !hasSelectAction">
        <!--     empty column     -->
      </td>
    </tr>
  </ng-template>
</p-table>

<p-contextMenu #cm [model]="contextMenu" appendTo="body" class="rowActionContextMenu"></p-contextMenu>
<ui-column-picker #columnPicker (onReset)="onColumnChange.emit(null)" (onSave)="onColumnChange.emit($event)" [(ngModel)]="columns"
                  [availableColumns]="availableColumns" name="columns"></ui-column-picker>
