import {_, MaybePromise} from '@wspsoft/underscore';
import * as html2Pdf from 'html-to-text';

import {AbstractKolibriScriptExecutor} from '../api/abstract-kolibri-script-executor';
import {CriteriaConditionOperator, CriteriaConditionOperatorOnly} from '../criteria/criteria-condition-operator';
import {CriteriaFunction} from '../criteria/criteria-function';

import {CriteriaOperator} from '../criteria/criteria-operator';
import {CriteriaConditionJson} from '../criteria/json/criteria-condition-json';
import {CriteriaQueryGroupJson} from '../criteria/json/criteria-query-group-json';
import {CriteriaQueryJson} from '../criteria/json/criteria-query-json';
import {AccessControl, AccessControlType} from '../model/database/access-control';

import {KolibriEntity} from '../model/database/kolibri-entity';

import {Field} from '../model/response/field';


import {AggregationFunction, Attribute, CardinalityEnum, Relation, Type} from '../model/xml/models';

import {AbstractModelService} from '../service/coded/abstract-model.service';
import {EntityModel} from '../service/entities/entity-model';
import {AbstractEntityService} from '../service/generated/abstract-entity-service';
import {Constants} from './constants';

export enum EvalAttributeType {
  defaultValue, parse, stringify, typecheck
}

export abstract class Utility {
  public static readonly ID_SUFFIX: string = 'Id';
  public static readonly ENTITY_SHARE_CUSTOM: string[] = [AccessControlType.entity, AccessControlType.share, AccessControlType.custom];
  public static readonly READ_CREATE_UPDATE_DELETE_SEARCH: string[] = [Constants.READ, Constants.CREATE, Constants.UPDATE, Constants.DELETE, Constants.SEARCH];

  /**
   * check if value is within range 0 - 65535
   */
  public static isPort(value: any): boolean {
    return Number.isInteger(value = parseInt(value, 10)) && value >= 0 && value <= 65535;
  }

  /**
   * convert name to plural rest form
   */
  public static restifyName(s: string): string {
    return _.decapitalize(s) + 's';
  }

  /**
   * convert plural rest name back to singular
   */
  public static unrestifyName(s: string): string {
    return _.capitalize(s).slice(0, s.length - 1);
  }

  /**
   * get id field access for to one relations
   */
  public static parameterizeEntityName(entity: string): string {
    return entity.endsWith('Id') ? entity : _.decapitalize(entity) + this.ID_SUFFIX;
  }

  /**
   * remove id suffix from id access
   */
  public static unparameterizeEntityName(entity: string): string {
    return entity.endsWith('Id') ? entity.replace('Id', '') : entity;
  }

  /**
   * get name of a path (string after last /)
   */
  public static getNameOfPath(path: string): string {
    return _.last(path.split('/'));
  }

  /**
   * do a dotwalk but use a specific suffix for access
   * ATTENTION: this is only for sync usage nothing will be awaited
   * @param data iterate object
   * @param field field name
   * @param suffix appended to field name
   * @returns the found value or undefined
   */
  public static dotWalkWithSuffix(data: any, field: string, suffix: string = ''): any {
    let current = data;
    const path = field.split('.');
    for (let i = 0; i < path.length; i++) {
      if (current === undefined) {
        return;
      }

      const dotWalk = path[i];
      const syncName = dotWalk + suffix;
      if (i === path.length - 1) {
        current = current[dotWalk];
      } else {
        current = current[syncName];
      }
    }
    return current;
  }

  /**
   * perform an async dotWalk on an object or array
   * @param data iterate object or array
   * @param field field name
   * @param cb cb invoked when finished (might access relations)
   * @param value value to set
   * @param nullIt force setting null
   */
  public static doDotWalk(data: any | any[], field: string, cb: (x: any) => void, value: any = null, nullIt: boolean = false): void {
    const split = field.split('.');
    this.dotWalk(0, data, split, value, nullIt, cb);
  }

  /**
   * evaluate position of % in term and return matching operator
   */
  public static getSearchOperatorFromMatchMode(matchMode: string, value: string = ''): CriteriaOperator {
    switch (matchMode) {
      case 'contains':
        return CriteriaOperator.CONTAINS;
      case 'notContains':
        return CriteriaOperator.NOT_CONTAINS;
      case 'dateIs':
      case 'equals':
        return CriteriaOperator.EQUAL;
      case 'dateIsNot':
      case 'notEquals':
        return CriteriaOperator.NOT_EQUAL;
      case 'lte':
        return CriteriaOperator.LESS_OR_EQUAL;
      case 'lt':
      case 'dateBefore':
        return CriteriaOperator.LESS;
      case 'gte':
        return CriteriaOperator.GREATER_OR_EQUAL;
      case 'gt':
      case 'dateAfter':
        return CriteriaOperator.GREATER;
      case 'startsWith':
        if (value.startsWith('%')) {
          return CriteriaOperator.CONTAINS;
        }
        return CriteriaOperator.BEGINS_WITH;
      case 'endsWith':
        if (value.endsWith('%')) {
          return CriteriaOperator.CONTAINS;
        }
        return CriteriaOperator.ENDS_WITH;
      case 'in':
        return CriteriaOperator.IN;
      case 'notBetween':
        return CriteriaOperator.NOT_BETWEEN;
      case 'between':
        return CriteriaOperator.BETWEEN;
    }
  }

  /**
   * evaluate position of % in term and return matching operator
   */
  public static getSearchOperator(query: any, preferredDefaultSearchOperator: CriteriaOperator = CriteriaOperator.BEGINS_WITH): CriteriaOperator {
    if (typeof query === 'string') {
      if (query.startsWith('%')) {
        return CriteriaOperator.CONTAINS;
      }
      return preferredDefaultSearchOperator;
    }
    return CriteriaOperator.EQUAL;
  }

  /**
   * remove redundant % from query string
   */
  public static stripPercentByOperator(operator: CriteriaOperator, value: string): string {

    // clear out percents
    if (operator === CriteriaOperator.BEGINS_WITH || operator === CriteriaOperator.CONTAINS) {
      value = value.replace(/^%/, '');
    }
    if (operator === CriteriaOperator.ENDS_WITH || operator === CriteriaOperator.CONTAINS) {
      value = value.replace(/%$/, '');
    }
    return value;
  }

  /**
   * checks if value matches a given query (using % search)
   * @param {string} value value to match
   * @param {string} query search term
   * @param {boolean} exact true => ignore %
   * @param searchOperator if given the % is ignored and the search method will be used
   * @returns {boolean}
   */
  public static matches(value: string, query?: string, exact: boolean = false, searchOperator?: CriteriaOperator): boolean {
    if (_.isNull(query)) {
      return true;
    }
    if (_.isNull(value)) {
      return false;
    }

    value = value.toLowerCase();

    if (exact) {
      return value === query.toLocaleLowerCase();
    }

    searchOperator = Utility.getSearchOperator(query, searchOperator);
    query = query.replace('%', '').toLowerCase();

    if (searchOperator === CriteriaOperator.BEGINS_WITH) {
      return value.startsWith(query);
    } else {
      return value.includes(query);
    }
  }

  /**
   * check if field name is a dotwalk
   */
  public static isDotWalk(x: string): boolean {
    return x.includes('.');
  }

  /**
   * replaces . with _ and appends Cached (used in datatable for cached data)
   */
  public static wordifyDotWalk(x: string, skipLast: boolean = false): string {
    if (skipLast) {
      return this.getDotWalkPath(x).join('_');
    }

    return x.replace('.', '_');
  }

  /**
   * get everything from a dotwalk except the last field
   */
  public static getDotWalkPath(x: string): string[] {
    return _.initial(x.split('.'));
  }

  /**
   * get the final name of a dotwalk
   */
  public static getDotWalkTarget(x: string): string {
    return _.last(x.split('.'));
  }

  /**
   * get the first name of a dotwalk
   */
  public static getDotWalkOrigin(x: string): string {
    return x.split('.')[0];
  }

  /**
   * checks if url matches error url
   */
  public static isErrorPage(url: string): boolean {
    return url.includes('/error/');
  }

  public static isAttribute(field?: Field): boolean {
    return field?.entityClass === 'Attribute';
  }

  public static isRelation(field?: Field): boolean {
    return field?.entityClass === 'Relation';
  }

  public static evalAttributeType<E extends KolibriEntity>(modelService: AbstractModelService,
                                                           attribute: Attribute,
                                                           type: EvalAttributeType, scriptExecutorService?: AbstractKolibriScriptExecutor,
                                                           record?: E, sql?: boolean): MaybePromise<any> {
    const type1: Type = modelService.getType(attribute.typeId);
    const recordOld = record?.recordOld || {};

    switch (type) {
      case EvalAttributeType.defaultValue:
        if (!attribute.computed) {
          const script: string = modelService.getDisplayTransformation(attribute.displayTransformId)?.defaultValue || type1.defaultValue;

          if (!script) {
            return attribute.multiple ? [] : null;
          }

          return scriptExecutorService.runScript(script, {
            record
          }, undefined, `Attribute:${attribute.name}:defaultValue`).result;
        }
        return scriptExecutorService.runScript(attribute.script, {record}, undefined, `Attribute:${attribute.name}:computedValue`).result;
      case EvalAttributeType.parse:
        return this.runParseOrStringify(sql, type1, 'parseScript', record, attribute, scriptExecutorService, recordOld);
      case EvalAttributeType.stringify:
        return this.runParseOrStringify(sql, type1, 'stringifyScript', record, attribute, scriptExecutorService, recordOld);
      case EvalAttributeType.typecheck:
        const checkScript: string = type1.checkScript;

        if (!checkScript) {
          return true;
        }

        const newValue = record[attribute.name];

        // if attribute is multiple check that every array entry is correct type
        if (attribute.multiple === true) {
          if (Array.isArray(newValue)) {
            return newValue.every(value => scriptExecutorService.runScript<boolean>(checkScript, {
              record,
              newValue: value,
              // non extended entities do not have a record old
              oldValue: recordOld[attribute.name],
              field: attribute.name
            }).result);
          } else {
            // if newValue is null type is ok, else it is multiple but no array, so it is false
            return !newValue;
          }
        }

        return scriptExecutorService.runScript<boolean>(checkScript, {
          record,
          newValue,
          // non extended entities do not have a record old
          oldValue: recordOld[attribute.name],
          field: attribute.name
        }).result;

    }
  }

  public static isFunctionalOperator(operator: CriteriaConditionOperator): boolean {
    switch (operator) {
      case CriteriaConditionOperatorOnly.SCRIPT:
      case CriteriaConditionOperatorOnly.CHANGES:
      case CriteriaConditionOperatorOnly.NOT_CHANGES:
      case CriteriaConditionOperatorOnly.CHANGES_TO:
      case CriteriaConditionOperatorOnly.NOT_CHANGES_TO:
      case CriteriaConditionOperatorOnly.CHANGES_FROM:
      case CriteriaConditionOperatorOnly.NOT_CHANGES_FROM:
        return true;
      default:
        return false;
    }
  }

  public static isNumericalDateFunction(operator: CriteriaFunction): boolean {
    switch (operator) {
      case CriteriaFunction.DATE_DAYOFWEEK:
      case CriteriaFunction.DATE_DAY:
      case CriteriaFunction.DATE_YEAR:
      case CriteriaFunction.DATE_HOUR:
      case CriteriaFunction.DATE_ISOWEEK:
      case CriteriaFunction.DATE_MONTH:
        return true;
      default:
        return false;
    }
  }

  public static isSubstringOperator(operator: CriteriaOperator): boolean {
    switch (operator) {
      case CriteriaOperator.CONTAINS:
      case CriteriaOperator.NOT_CONTAINS:
      case CriteriaOperator.BEGINS_WITH:
      case CriteriaOperator.NOT_BEGINS_WITH:
      case CriteriaOperator.ENDS_WITH:
      case CriteriaOperator.NOT_ENDS_WITH:
        return true;
      default:
        return false;
    }
  }

  public static isOperatorOnly(operator: CriteriaOperator | CriteriaConditionOperator): boolean {
    switch (operator) {
      case CriteriaConditionOperatorOnly.NOT_CHANGES:
      case CriteriaConditionOperatorOnly.CHANGES:
      case CriteriaOperator.IS_EMPTY:
      case CriteriaOperator.IS_NOT_EMPTY:
      case CriteriaOperator.IS_NULL:
      case CriteriaOperator.IS_NOT_NULL:
        return true;
      default:
        return false;
    }
  }

  public static isDateOperator(operator: CriteriaOperator): boolean {
    switch (operator) {
      case CriteriaOperator.DATE_RANGE:
        return true;
      default:
        return false;
    }
  }

  public static convertFormPayload(payload: string): any {
    return JSON.parse(payload.replace(/'/g, '"').replace(/\n/g, ''));
  }

  public static isToOneRelation(relation: Relation): boolean {
    return relation.cardinality === CardinalityEnum.ManyToOne || relation.cardinality === CardinalityEnum.OneToOne;
  }

  public static isToManyRelation(relation: Relation): boolean {
    return relation.cardinality === CardinalityEnum.OneToMany || relation.cardinality === CardinalityEnum.ManyToMany;
  }

  /**
   * retry function x times
   * @param fn function to call
   * @param retries max number of retries
   * @param currentTry current try (recursion only)
   * @returns result of fn
   */
  public static async retry<T>(fn: () => Promise<T>, retries: number = 3, currentTry: number = 0): Promise<T> {
    try {
      return await fn();
    } catch (e: any) {
      if (currentTry === retries) {
        throw e;
      }

      return this.retry(fn, retries, currentTry + 1);
    }
  }

  /**
   * calculate all differences between 2 objects
   * a is the new record
   * b is considered old
   */
  public static differences(a: any, b: any): { field: string; newValue: any; oldValue: any }[] {
    function changes(current: any, old: any): { [key: string]: { field: string; newValue: any; oldValue: any } } {
      return _.transform(current, (result, value, key: string) => {
        if (!_.isEqual(value, old[key])) {
          result[key] = {
            field: key,
            oldValue: old[key],
            newValue: current[key],
          };
        }
      });
    }

    return Object.values(changes(a, b));
  }

  /**
   * extract the header with id subject from the email template
   */
  public static extractSubjectAndBodyFromEmailTemplate(emailHtml: string): { subject: string; body: string } {
    const regExp = /<header id="subject">[\s\S]*<\/header>/m;
    const matches = emailHtml.match(regExp);

    if (!matches) {
      console.error(`No header found in email template ${emailHtml}`, this);
    }

    return {
      subject: html2Pdf.convert(matches[0] || '', {
        wordwrap: false
      }),
      body: emailHtml.replace(regExp, '')
    };
  }

  /**
   * Extracts adaptive card from body and returns its json value
   * @param {string} body
   * @returns {string}
   */
  public static extractAdaptiveCardFromBody(body: string): string {
    // regEx captures all adaptive cards, indicated by special script tag
    // get match and only return capturing group
    const regExpMatch = /<script type="application\/adaptivecard\+json">(.*?)<\/script>/gs.exec(body);
    return regExpMatch ? regExpMatch[1] : '';
  }


  /**
   * This function converts a mimetype to its proper extension. Note: currently only usable for standard images.
   * @param {string} mimetype the mimetpe that needs to be converted.
   * @returns {string} the file extension that fits the mimetype.
   */
  public static getExtensionForMimetype(mimetype: string): string {
    switch (mimetype) {
      case 'image/png':
      case 'image/x-citrix-png':
      case 'image/x-png':
        return '.png';
      case 'image/jpeg':
      case 'image/x-citrix-jpeg':
        return '.jpg';
      case 'image/pjpeg':
        return '.pjpeg';
      default:
        return '';
    }
  }

  /**
   * escapes leading = and "
   * also wrapps everything around "
   */
  public static escapeCsvData(data: string): string {
    return typeof data === 'string' ? `"${data.replace(/^=/g, '\'=').replace(/"/g, '""')}"` : data;
  }

  /**
   * get all used conditions as array
   */
  public static getAllConditions(currentGroup: CriteriaQueryGroupJson | CriteriaQueryJson): CriteriaConditionJson[] {
    let result = [];
    for (const group of currentGroup.groups ?? []) {
      result = result.concat(this.getAllConditions(group));
    }
    return result.concat(currentGroup.whereCondition ?? []);
  }

  /**
   * Checks if the selected Field is a ManyToMany or OneToMany relation
   */
  public static isToManyField(field: Field): boolean {
    return (field as Relation).cardinality === CardinalityEnum.ManyToMany || (field as Relation).cardinality === CardinalityEnum.OneToMany;
  }

  /**
   * apply aggregation function on array of objects
   */
  public static aggregate(field: string, aggregation: AggregationFunction, values: any[]): number {
    if (values) {
      switch (aggregation) {
        case AggregationFunction.COUNT:
          return values.length;
        case AggregationFunction.SUM:
          return _.sumBy(values, field);
        case AggregationFunction.AVG:
          return _.sumBy(values, field) / (values.filter(v => !_.isNull(v[field]))).length;
      }
    }
  }

  /**
   * find the parts that are missing from the prev text
   */
  public static findSuffixToAppend(prevText: string, textToAppend: string): string {
    const textToInsert = textToAppend;
    prevText = prevText.toLowerCase();
    textToAppend = textToAppend.toLowerCase();
    for (let i = 1; i <= textToAppend.length; i++) {
      const searchString = textToAppend.substring(0, i);
      if (!prevText.includes(searchString)) {
        return textToInsert.substring(i - 1);
      }
    }
    // nothing to add
    return '';
  }

  /**
   * bulk load relation and fill relation fields
   */
  public static async bulkDotWalk<E extends KolibriEntity, T extends KolibriEntity>(values: E[], field: string,
                                                                                    service: AbstractEntityService<E>): Promise<T[]> {
    const relations = await service.getEntityRelations<T>(values,
      field);
    for (let i = 0; i < relations.length; i++) {
      // fill caches for every object
      values[i][field] = relations[i];
    }
    return relations;
  }

  public static getRepresentativeStringOfGroupBy(entity: string, groupBy: string, modelService: AbstractModelService): string {
    const entityByName = modelService.getEntity(entity);

    const fields = modelService.getFields(entityByName.id, groupBy);
    if (Utility.isRelation(fields.fields[0])) {
      if (Utility.isToOneRelation(fields.fields[0])) {
        return Utility.parameterizeEntityName(fields.fields[0].name);
      }

      return groupBy + '.id';
    }

    return groupBy;
  }

  /**
   * sort everything by key to always have the same order
   */
  public static sortByKeys<T extends Record<any, any>>(values: T): T;

  public static sortByKeys<T extends Record<any, any>>(values: T[]): T[];

  public static sortByKeys<T extends Record<any, any>>(values: T | T[]): T | T[] {
    const sortByKey = (unordered: any): any => {
      if (typeof unordered !== 'object') {
        return unordered;
      }
      return Object.keys(unordered).sort().reduce(
        (obj, key) => {
          if (typeof unordered[key] === 'object' && !_.isNull(unordered[key])) {
            obj[key] = this.sortByKeys(unordered[key]);
          } else {
            obj[key] = unordered[key];
          }

          return obj;
        },
        {}
      );
    };

    return Array.isArray(values) ? values.map(sortByKey) : sortByKey(values);
  }

  /**
   * verify that entityMeta is a real ArangoDB collection
   */
  public static isCollection(entityMeta: EntityModel): boolean {
    if (entityMeta.virtual) {
      // we need to check if entity is part of the single collection mapper actually
      if (entityMeta.virtualCollectionId === '3f771f9b-e595-4dbc-8178-3bdcf6c265f1') {
        return entityMeta.ancestors.some(x => !x.abstract && !x.virtual);
      }
      return false;
    }
    return !entityMeta.abstract;
  }

  /**
   * get the real ArangoDB collection name from entityMeta
   */
  public static getCollectionName(entityMeta: EntityModel): string {
    if (entityMeta.virtual) {
      // we need to check if entity is part of the single collection mapper actually
      if (entityMeta.virtualCollectionId === '3f771f9b-e595-4dbc-8178-3bdcf6c265f1') {
        return entityMeta.ancestors.find(x => !x.abstract && !x.virtual)?.name;
      }
      return;
    }
    if (!entityMeta.abstract) {
      return entityMeta.name;
    }
  }

  /**
   * treat custom, share and entity as entity (this was the old behavior)
   */
  public static convertLegacyAclType(acl: AccessControl): AccessControlType {
    if (this.ENTITY_SHARE_CUSTOM.includes(acl.type)) {
      // the old SHARE_PUBLIC, SHARE_PRIVATE and SHARE_GROUP are treated as entity type to avoid breaking changes
      if (acl.type === AccessControlType.share && _.some(acl.operation,
        o => this.READ_CREATE_UPDATE_DELETE_SEARCH.includes(o))) {
        return AccessControlType.share;
      }
      return AccessControlType.entity;
    }
    return acl.type;
  }

  public static asyncFilter<T>(arr: T[], predicate: (value: T, index: number) => MaybePromise<boolean>): Promise<T[]> {
    return _.parallelMap(arr, (value, index) => predicate(value, index)).then(results => arr.filter((_v, index) => results[index]));
  }

  public static async asyncSome<T>(arr: T[], predicate: (value: T, index: number) => MaybePromise<boolean>): Promise<boolean> {
    for (const [index, value] of arr.entries()) {
      if (await predicate(value, index)) {
        return true;
      }
    }
    return false;
  }

  public static async asyncEvery<T>(arr: T[], predicate: (value: T, index: number) => MaybePromise<boolean>): Promise<boolean> {
    for (const [index, value] of arr.entries()) {
      if (!await predicate(value, index)) {
        return false;
      }
    }
    return true;
  }

  private static runParseOrStringify<E>(sql: boolean, type: Type, scriptName: string, record: E, attribute: Attribute,
                                        scriptExecutorService: AbstractKolibriScriptExecutor,
                                        recordOld: E): any {
    const value = record[attribute.name];
    if (sql && type.sqlOnly || !type.sqlOnly) {
      const parseScript: string = type[scriptName];

      if (!parseScript || _.isNull(value)) {
        return value;
      }

      // if attribute is multiple check that every array entry is correct type
      if (attribute.multiple === true && Array.isArray(value)) {
        const parsedValues = [];
        for (const v of value) {
          parsedValues.push(scriptExecutorService.runScript(parseScript, {
            record,
            newValue: v,
            // non extended entities do not have a record old
            oldValue: recordOld[attribute.name],
            field: attribute.name
          }, undefined, `Attribute:${attribute.name}:${scriptName}`).result);
        }

        return parsedValues;
      }

      return scriptExecutorService.runScript(parseScript, {
        record,
        newValue: value,
        // non extended entities do not have a record old
        oldValue: recordOld[attribute.name],
        field: attribute.name
      }, undefined, `Attribute:${attribute.name}:${scriptName}`).result;
    }
    return value;
  }

  /**
   * perform an async dotWalk on an object or array
   * @param currentIdx the current index of dotWalk
   * @param current iterate object or array
   * @param path the dotWalk as array
   * @param value value to set
   * @param nullIt force setting null
   * @param cb cb invoked when finished (might access relations)
   * @private
   */
  private static dotWalk(currentIdx: number, current: any | any[], path: string[], value: any, nullIt: boolean, cb: (x: any) => void = () => {/**/
  }): void {
    if (Array.isArray(current)) {
      this.manyDotWalk(currentIdx, current, path, value, nullIt, cb);
    } else {
      this.oneDotWalk(currentIdx, current, path, value, nullIt, cb);
    }
  }

  /**
   * perform an async dotWalk on an array
   * @param currentIdx the current index of dotWalk
   * @param current iterate object
   * @param path the dotWalk as array
   * @param value value to set
   * @param nullIt force setting null
   * @param cb cb invoked when finished (might access relations)
   * @private
   */
  private static manyDotWalk(currentIdx: number, current: any[], path: string[], value: any, nullIt: boolean, cb: (x: any) => void): void {
    if (current === undefined || current === null || current.length === 0) {
      cb(current);
      return;
    }
    let count = current.length;
    const newCurrent = [];
    let locking = false;
    void _.parallelDo(current, one => this.oneDotWalk(currentIdx, one, path, value, nullIt, (v) => {
      // lock to ensure that the parallel flow does not cause any problems
      while (locking) {
      }
      locking = true;
      try {
        newCurrent.push(v);
        if (0 === --count) {
          cb(newCurrent);
        }
      } finally {
        locking = false;
      }
    }));
  }

  /** 5
   * perform an async dotWalk on one object
   * @param currentIdx the current index of dotWalk
   * @param current iterate object
   * @param path the dotWalk as array
   * @param value value to set
   * @param nullIt force setting null
   * @param cb cb invoked when finished (might access relations)
   * @private
   */
  private static oneDotWalk(currentIdx: number, current: any, path: string[], value: any, nullIt: boolean, cb: (x: any) => void): void {
    if (current === undefined || current === null) {
      cb(current);
      return;
    }

    const dotWalk = path[currentIdx];

    // is this the last value and do we want to set it?
    if (currentIdx === path.length - 1) {
      if (value !== null || nullIt) {
        current[dotWalk] = value;
      } else {
        _.maybeAwait(current[dotWalk], cb);
      }
      return;
    }

    currentIdx++;

    if (value !== undefined && !(dotWalk in current) || _.isNull(current[dotWalk]) && !(dotWalk in current)) {
      current[dotWalk] = {};
    }
    // check if a async variant exists and await result
    if ((dotWalk in current)) {
      _.maybeAwait(current[dotWalk], (newCurrent) => {
        if (Array.isArray(newCurrent)) {
          this.manyDotWalk(currentIdx, newCurrent, path, value, nullIt, cb);
        } else {
          this.oneDotWalk(currentIdx, newCurrent, path, value, nullIt, cb);
        }
      });
    } else {
      this.oneDotWalk(currentIdx, current[dotWalk], path, value, nullIt, cb);
    }
  }
}
