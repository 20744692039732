import {_, MaybePromise} from '@wspsoft/underscore';
import {AbstractKolibriScriptExecutor} from '../../../api/abstract-kolibri-script-executor';
import {CriteriaQuery} from '../../../criteria/criteria-query';
import {AccessControlType} from '../../../model/database/access-control';
import {OneMenuFlags} from '../../../model/database/json/one-menu-state';
import {UserSessionSettings} from '../../../model/database/json/user-session.settings';
import {KolibriEntity} from '../../../model/database/kolibri-entity';
import {User} from '../../../model/database/user';
import {UserProfile} from '../../../model/database/user-profile';
import {Constants} from '../../../util/constants';
import {Utility} from '../../../util/utility';
import {AbstractModelService} from '../../coded/abstract-model.service';
import {EntityModel} from '../../entities/entity-model';
import {LazyLoaderHandler} from './lazy-loader-handler';

export interface AccessControlCacheData {
  roles: string[];
  condition: string;
  filter: string;
  adminOverrides: boolean;
  id: string;
  order: number;
}

export interface AccessControlCache {
  [type: string]: {
    [operation: string]: {
      [resource: string]: {
        ancestor: string;
        [level: string]: AccessControlCacheData[] | string;
      };
    };
  };
}

export enum ACLOperations {
  'CREATE', 'READ', 'UPDATE'
}

export abstract class UserHandler extends LazyLoaderHandler<User> {
  public static addLazyLoader(proto: any, entityMeta: EntityModel): void {
    super.addLazyLoader(proto, entityMeta);

    const activeProfile = Object.getOwnPropertyDescriptor(proto, 'activeProfile');
    Object.defineProperty(proto, 'activeProfile', {
      ...activeProfile, get() {
        const result = activeProfile.get.call(this);
        if (_.isPromise(result)) {
          return result.then(x => setUserSessionSettings(x));
        }
        return result;
      }
    });
  }

  public hasRole(this: User, role?: string, ignoreAdminRole: boolean = false): boolean | Promise<boolean> {
    if (this.roles === undefined) {
      return (typeof global === 'undefined' ?
          fetch(`/auth/evalGroupMembership/${this.id}`)
            .then(res => res.json())
            .then(x => {
              this.groups = x.groups;
              this.roles = x.roles;
              return this.hasRole(role, ignoreAdminRole);
            }) :
          global.authGuard.evalGroupMemberships(this, this.groups = {}, this.roles = {})
            .then(() => this.hasRole(role, ignoreAdminRole))
      );
    }

    if (!role) {
      // if no role was defined and the user has no role return true
      return !_.isEmpty(this.roles);
    }

    if (!ignoreAdminRole && this.roles.AdminRole) {
      return true;
    }

    return !!(this.roles && this.roles[role]?.active);
  }

  public hasRoles(this: User): boolean | Promise<boolean> {
    if (this.roles === undefined) {
      return (typeof global === 'undefined' ?
          fetch(`/auth/evalGroupMembership/${this.id}`)
            .then(res => res.json())
            .then(x => {
              this.groups = x.groups;
              this.roles = x.roles;
              return this.hasRoles();
            }) :
          global.authGuard.evalGroupMemberships(this, this.groups = {}, this.roles = {})
            .then(() => this.hasRoles())
      );
    }

    // check if the user has any role
    return !_.isEmpty(this.roles);
  }

  public can(this: User, operation: string, resource: string, level?: string,
             options?: {
               type?: AccessControlType; record?: KolibriEntity;
               additionalTypes?: AccessControlType[]; query?: CriteriaQuery<KolibriEntity>;
             }): Promise<boolean>;
  public can(this: User, operation: string[], resource: string, level?: string,
             options?: {
               type?: AccessControlType; record?: KolibriEntity;
               additionalTypes?: AccessControlType[]; query?: CriteriaQuery<KolibriEntity>;
             }): Promise<boolean[]>;
  public can(this: User, operation: string | string[], resource: string = '*', level: string = null,
             {
               type = AccessControlType.entity,
               record,
               additionalTypes,
               query
             }: {
               type?: AccessControlType; record?: KolibriEntity;
               additionalTypes?: AccessControlType[]; query?: CriteriaQuery<KolibriEntity>;
             } = {}): Promise<boolean | boolean[]> {
    if (Array.isArray(operation)) {
      return _.parallelMap(operation, op => this.can(op, resource, level, {type, record, additionalTypes, query}));
    }

    if (additionalTypes) {
      // every type must evaluate to true
      return Utility.asyncEvery([type, ...additionalTypes],
        subType => this.can(operation, resource, level, {type: subType, record, query}));
    }
    const aclData = this.aclData();
    const acls = aclData.cache[type]?.[operation]?.[resource]?.[level];

    if (typeof window !== 'undefined' && record && type === AccessControlType.entity && Constants.DEFAULT_OPERATIONS.includes(operation)) {
      return (this as any).checkClientSidePermission(record, operation, level);
    }

    if (acls && typeof acls !== 'string') {
      if (operation === Constants.SEARCH) {
        return (this as any).executeSearchAcls(acls, operation, aclData, record, query);
      }
      return (this as any).executeBooleanAcls(acls, operation, aclData, record, query);
    }
    return (this as any).ascendAcl(resource, aclData, level, type, operation, record, additionalTypes, query);
  }

  public hasGroup(this: User, group: string, ignoreAdminRole: boolean = false): boolean | Promise<boolean> {
    if (this.roles === undefined) {
      return (typeof global === 'undefined' ?
          fetch(`/auth/evalGroupMembership/${this.id}`)
            .then(res => res.json())
            .then(x => {
              this.groups = x.groups;
              this.roles = x.roles;
              return this.hasGroup(group, ignoreAdminRole);
            }) :
          global.authGuard.evalGroupMemberships(this, this.groups = {}, this.roles = {})
            .then(() => this.hasGroup(group, ignoreAdminRole))
      );
    }

    if (!ignoreAdminRole && this.roles.AdminRole) {
      return true;
    }

    return !!(this.groups && this.groups[group]?.active);
  };

  public hasTenant(this: User, tenant: string): boolean | Promise<boolean> {
    if (this.roles === undefined) {
      return (typeof global === 'undefined' ?
          fetch(`/auth/evalTenantMembership/${this.id}`)
            .then(res => res.json())
            .then(x => {
              this.tenants = x.tenants;
              return this.hasTenant(tenant);
            }) :
          global.authGuard.evalTenantMemberships(this, this.tenants = {})
            .then(() => this.hasTenant(tenant))
      );
    }

    if (!tenant) {
      return true;
    }
    // the user is assigned directly to the tenant itself
    if (this.tenants && this.tenants[tenant]?.active) {
      return true;
    }

    // check if tenant is child of any assigned tenants
    for (const tenantData of Object.values(this.tenants || {})) {
      const child = tenantData.children.find(childData => childData.id === tenant || childData.name === tenant);
      if (child) {
        return child.active;
      }
    }

    return false;
  };

  /**
   * check acl result based on the _securityInfo results calculated by the server beforehand
   * IMPORTANT: this is only used, because the ACL's shouldn't be recalculated everytime something changes on the record
   */
  public checkClientSidePermission(record: KolibriEntity, operation: string, level: string): boolean {
    if (level) {
      // the access level for the fieldInfo is saved as a string with "1" or "0" based on your allowance to CREATE, READ or UPDATE
      // "ACLOperations[operation]" resolves to either 1,2 or 3 depending on the actual level
      let canReadField = true;
      if (record._securityInfo?.fieldInfo?.[level]?.at(ACLOperations.READ)) {
        canReadField = !!Number(record._securityInfo.fieldInfo?.[level]?.at(ACLOperations[operation]));
      }
      return canReadField;
    }

    return record._securityInfo?.[`can${operation}`] ?? true;
  }

  /**
   * execute found acl rules and return if the user can perform the operation (any is true)
   */
  private async executeSearchAcls(this: User, acls: AccessControlCacheData[], operation: string,
                                  aclData: { cache: AccessControlCache; jsContext: AbstractKolibriScriptExecutor }, record: KolibriEntity,
                                  query: CriteriaQuery<KolibriEntity>): Promise<boolean> {
    const aclGroup = query.addGroup();
    const aclSubQueries = [];
    for (const acl of acls) {
      if (acl.adminOverrides && operation !== 'admin' && this.roles.AdminRole) {
        return true;
      }

      if (!acl.roles.length || acl.roles.some(role => this.hasRole(role, !acl.adminOverrides))) {
        let apply = false;
        if (!acl.condition) {
          apply = true;
        } else {
          apply = await aclData.jsContext.runScript<boolean | Promise<boolean>>(acl.condition, {user: this, record, query},
            undefined, `AccessControl:${acl.id}:condition`, false).result;
        }

        if (apply) {
          if (!acl.filter) {
            return true;
          }

          aclSubQueries.push({filter: acl.filter, id: acl.id});
        }
      }
    }

    await _.parallelDo(aclSubQueries, async aclSubQuery => {
      const subAclGroup = aclGroup.addGroup(true);
      await aclData.jsContext.runScript<MaybePromise<void>>(aclSubQuery.filter, {
        user: this, record, query: subAclGroup as any, tenancyFilter: query.pTenancyFilter
      } as any, undefined, `AccessControl:${aclSubQuery.id}:filter`, false).result;
    });

    return false;
  }

  /**
   * execute found acl rules and return if the user can perform the operation (any is true)
   */
  private executeBooleanAcls(this: User, acls: AccessControlCacheData[], operation: string,
                             aclData: { cache: AccessControlCache; jsContext: AbstractKolibriScriptExecutor }, record: KolibriEntity,
                             query: CriteriaQuery<KolibriEntity>): Promise<boolean> {
    return Utility.asyncSome(acls, acl => {
      if (acl.adminOverrides && operation !== 'admin' && this.roles.AdminRole) {
        return true;
      }

      if (!acl.roles.length || acl.roles.some(role => this.hasRole(role, !acl.adminOverrides))) {
        if (!acl.condition) {
          return true;
        } else {
          return aclData.jsContext.runScript<boolean>(acl.condition, {user: this, record, query},
            undefined, `AccessControl:${acl.id}:condition`, false, false).result as Promise<boolean>;
        }
      }
      return false;
    });
  }

  /**
   * move on level up to find the parent acls
   */
  private ascendAcl(this: User, resource: string, aclData: { cache: AccessControlCache; modelService: AbstractModelService },
                    level: string, type: AccessControlType, operation: string, record: KolibriEntity, additionalTypes: AccessControlType[],
                    query: CriteriaQuery<KolibriEntity>): MaybePromise<boolean> {
    // if we have a defined resource and level, check start level first
    if (resource !== '*') {
      const entity = aclData.modelService.getEntity(resource);

      // is the level still a valid field (userGroup.active, nextIteration: kolibriEntity.active)
      if (level !== '*' && level !== null) {
        if (entity?.ancestor) {
          // check if the current resource has a * level (userGroup.*)
          if (aclData.cache[type]?.[operation]?.[resource]?.['*']) {
            // this will find an acl and check the * level of myself (userGroup.*)
            return this.can(operation, resource, '*', {type, record, additionalTypes, query});
          } else {
            // we do not have our own acl for any field, so jump to the next level (kolibriEntity.active)
            return this.can(operation, entity.ancestor.name, level, {type, record, additionalTypes, query});
          }
        }
        // we have no ancestor so check * level (kolibriEntity.*)
        return this.can(operation, resource, '*', {type, record, additionalTypes, query});
      } else {
        // the field is already a * or null
        if (entity?.ancestor) {
          // check for ancestor level (kolibriEntity.*)
          return this.can(operation, entity.ancestor.name, level === null ? null : '*', {type, record, additionalTypes, query});
        }
        // try with operation only, this is super top level (*.*)
        return this.can(operation, '*', level === null ? null : '*', {type, record, additionalTypes, query});
      }
    }
    // nothing defined at all so admin only
    return !!this.roles.AdminRole;
  }
}

for (const i of ['groups',
  'roles',
  'tenants',
  'language',
  'timezone',
  'token',
  'impersonatorId',
  'rootTenantMode',
  'hasToAuthenticate',
  'loginMethod',
  'loginName']) {
  Object.defineProperty(UserHandler.prototype, i, {
    get() {
      return this.record[i];
    },
    set(v) {
      this.record[i] = v;
    },
    enumerable: true
  });
}

for (const i of ['aclData']) {
  Object.defineProperty(UserHandler.prototype, i, {
    get() {
      return this.record[i];
    },
    set(v) {
      this.record[i] = v;
    },
    enumerable: false
  });
}

function userSessionSettings(): UserSessionSettings {
  return {
    currentDashboardId: null,
    globalSearches: [],
    layoutStates: {},
    leftMenuState: {
      currentTab: OneMenuFlags.NAVIGATION,
      hidden: true
    },
    oneHistory: {
      favorites: [],
      history: []
    },
    pageRightStates: {},
    tableStates: {},
    userHotkeyOverwrites: {}
  };
}

function setUserSessionSettings(activeProfile: UserProfile): UserProfile {
  if (activeProfile) {
    if (activeProfile.sessionSettings) {
      for (const [key, value] of Object.entries(userSessionSettings())) {
        activeProfile.sessionSettings[key] ??= value;
      }
    } else {
      activeProfile.sessionSettings = userSessionSettings();
    }
  }
  return activeProfile;
}
