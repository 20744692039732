import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Host,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  SkipSelf
} from '@angular/core';
import {ControlContainer, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {CriteriaOperator, CriteriaQuery, CriteriaType, Entity, KolibriEntity} from '@wspsoft/frontend-backend-common';
import {_, MaybePromise} from '@wspsoft/underscore';
import {SelectItemGroup} from 'primeng/api';
import {CriteriaFactory, EntityService, EntityServiceFactory, ModelService} from '../../../../../../api';
import {UiUtil} from '../../../../util/ui-util';

import {KolibriEntityConverterService} from '../../../converter/kolibri-entity-converter.service';
import {AutoComplete} from '../autocomplete';

@Component({
  selector: 'ui-entity-autocomplete',
  templateUrl: './entity-autocomplete-input.component.html',
  styleUrls: ['../autocomplete.scss', './entity-autocomplete-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EntityAutocompleteInputComponent),
    multi: true
  }, {
    provide: KolibriEntityConverterService
  }],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new Optional(), new Host(), new SkipSelf(), ControlContainer]],
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityAutocompleteInputComponent extends AutoComplete<KolibriEntity | KolibriEntity[]> implements OnInit, OnChanges {
  public entityMeta: Entity = {};
  @Input()
  public groupBy: string;
  /**
   * name or id of entity
   */
  @Input()
  public entityId: string;
  @Input()
  public convertToString: boolean = false;
  @Input()
  public search: (result: CriteriaQuery<KolibriEntity>, searchString?: string) => MaybePromise<any>;
  private entityService: EntityService<KolibriEntity>;

  public constructor(private translateService: TranslateService, private modelService: ModelService, cdr: ChangeDetectorRef,
                     private criteriaFactory: CriteriaFactory, private entityConverter: KolibriEntityConverterService,
                     private entityServiceFactory: EntityServiceFactory) {
    super(cdr);
    this.converter = entityConverter;
    this.checkScrollDebounced = _.debounce(this.checkScroll, 50);
  }

  public get suggestions(): (KolibriEntity | KolibriEntity[])[] | SelectItemGroup[] {
    return super.suggestions;
  }

  public set suggestions(value: (KolibriEntity | KolibriEntity[])[] | SelectItemGroup[]) {
    // Move the current selected value to the top of the suggestion list if we are in single mode
    if (!this.multiple && this.value && !this.groupBy) {
      value = _.filter(value as KolibriEntity[], x => x.id !== (this.value as KolibriEntity).id);
      value.unshift(this.value as SelectItemGroup & (KolibriEntity | KolibriEntity[]));
    }
    super.suggestions = value;
  }

  public get getPillHeight(): string {
    const fontSize = parseInt(UiUtil.getVariable('basefont'));
    const pillHeight = parseFloat(UiUtil.getVariable('inlineTagHeight')?.replace(/[^\d\.]/ig, ''));
    return (pillHeight * fontSize).toString();
  }

  public ngOnInit(): void {
    this.entityConverter.convertToString = this.convertToString;
    this.entityConverter.entityNameOrId = this.entityId;
    this.entityMeta = this.modelService.getEntity(this.entityId);
    this.entityService = this.entityServiceFactory.getService(this.entityId);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.entityId && !changes.entityId.firstChange && changes.entityId.currentValue) {
      this.ngOnInit();
    }
  }

  public async onComplete($event: any): Promise<void> {
    await this.calculateSuggestions($event);
    if ($event.originalEvent.cb) {
      $event.originalEvent.cb();
    }
  }

  public async calculateSuggestions($event: any): Promise<void> {
    if (this.lastQuery !== $event.query) {
      this.queryOffset = 0;
    }
    this.lastQuery = $event.query;
    const query = this.criteriaFactory.getFrontendQuery<any>(this.entityMeta.name, CriteriaType.SELECT);
    if (this.search) {
      const result = this.search(query, $event.query);
      if (_.isPromise(result)) {
        await result;
      }
    }
    const textFilter = query.addGroup();
    textFilter.addPercentBasedCondition('representativeString', $event.query, undefined, this.searchOperator);
    for (const field of this.queryFields || []) {
      textFilter.addPercentBasedCondition(field, $event.query, true, this.searchOperator);
    }
    // if we dont have a query offset we query the full limit, otherwise we only need to load the next 5 records
    query.limit(this.queryOffset !== 0 ? this.queryOffsetSteps : this.size)
      .offset(this.queryOffset)
      .addOrder('representativeString');
    if (this.multiple && !_.isNullOrEmpty(this.value)) {
      // dont suggest values that are already contained in the input
      query.addCondition('id', CriteriaOperator.NOT_IN, (this.value as KolibriEntity[]).map(entity => entity.id));
    }

    const results = await query.getResults();
    // if we do not have an offset we can just use the results. Otherwise we need to append the query results to the already fetched results
    let suggestions = this.queryOffset !== 0 ? [...this.suggestions as KolibriEntity[], ...results] : results;

    if (this.groupBy && suggestions.length) {
      suggestions = await UiUtil.groupEntitiesIntoSelectItemGroups(suggestions, this.groupBy, this.modelService, this.entityService);
    }

    if (!suggestions.length && this.defaultEntry) {
      suggestions = [this.defaultEntry($event.query)];
    }
    this.suggestions = suggestions;
    this.cdr.detectChanges();
  }
}
